import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const Roadmap = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Roadmap',
    paragraph: 'This roadmap outlines our goals and where we want to take the Funky Planets and Universe. We have a lot of ideas and concepts that we are working on. It may evolve over time and hopefully become even better!'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  .01 Launch Roadmap
                </div>
                <p className="m-0">
                  We’ve launched our planets and we are really happy about it, but this is only the beginning, we have big plans for the whole funky universe. We are working on some cool ideas that will complete this amazing project. We also need to work hard with our community to make Discord and Twitter even cooler with new additions throughout time.
                </p>
              </div>
              <div>
                <Image
                  src={require('./../../assets/images/funky_planet_2895.png')}
                  alt="Funky Planet 01"
                  width={400}
                  height={400} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  .02 The Funky Universe is big
                </div>
                <p className="m-0">
                  As we said, the funky universe is big, really big, and the surprises to come will amaze everybody. You own your own planet and that is marvelous. We are working really hard in this phase of the project to bring new pieces to complement it.
                </p>
              </div>
              <div>
                <Image
                  src={require('./../../assets/images/funky_planet_2896.png')}
                  alt="Funky Planet 02"
                  width={400}
                  height={400} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  .03 Universe interaction
                </div>
                <p className="m-0">
                  Now all the pieces work together and you will be able to interact with other collectors and items. This collection is incredibly important to us, and we are excited to see what we can accomplish together! This team is committed to share the knowledge from all aspects of the NFT world, this amazing world.
                </p>
              </div>
              <div>
                <Image
                  src={require('./../../assets/images/funky_planet_2897.png')}
                  alt="Funky Planet 03"
                  width={400}
                  height={400} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;