const initialState = {
  loading: false,
  totalSupply: '?',
  cost: 0,
  maxMintAmount: 0,
  error: false,
  errorMsg: "",
  config: {
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    GAS_LIMIT: 0,
  },
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: ""
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        cost: action.payload.cost,
        maxMintAmount: action.payload.maxMintAmount,
        error: false,
        errorMsg: ""
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload
      };
    case "FETCH_DATA_CONFIG":
        return {
          ...state,
          config: action.payload,
        };
    default:
      return state;
  }
};

export default dataReducer;
