import React from 'react';
// import sections
import Introduction from '../components/sections/introduction';
import FunkyUniverse from '../components/sections/FunkyUniverse';
import Roadmap from '../components/sections/Roadmap';
import Team from '../components/sections/Team';

const Home = () => {

  return (
    <>
      <Introduction />
      <FunkyUniverse topDivider />
      <Roadmap invertMobile topDivider imageFill />
      <Team topDivider />
    </>
  );
}

export default Home;