import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FunkyUniverse = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'The Funky Universe',
    paragraph: 'These planets are the beginning of a funky universe with many surprises to come. Find planets that have life with different levels of technological advancement, some of them are going to even be in a space exploration era.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header" style={{ marginBottom: '10px' }}>
                  <Image
                    src={require('./../../assets/images/funky_planet_6.png')}
                    alt="FJV-071 e"
                    width={300}
                    height={300} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    FJV-071 e
                  </h4>
                  <p className="m-0 text-sm">
                    This is a superb planet named 'FJV-071 e' with an age of 7.783 billion earth years. 'FJV-071 e' contains water and one continent and has no satellites or rings. The duration of its day is 790 earth hours and the duration of its year is 481 earth days. There is life in this planet and this life is intelligent. The population is 8,074,370,656 individuals and the the civilization's level of technological advancement is 'pre space exploration'.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header" style={{ marginBottom: '10px' }}>
                  <Image
                    src={require('./../../assets/images/funky_planet_7.png')}
                    alt="70 EMA g"
                    width={300}
                    height={300} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    70 EMA g
                  </h4>
                  <p className="m-0 text-sm">
                    This is a cool planet named '70 EMA g' with an age of 8.005 billion earth years. '70 EMA g' presents a surface with craters and has two satellites and rings. The duration of its day is 903 earth hours and the duration of its year is 530 earth days. There is no life in this planet.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header" style={{ marginBottom: '10px' }}>
                  <Image
                    src={require('./../../assets/images/funky_planet_10.png')}
                    alt="GC 1156 k"
                    width={300}
                    height={300} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    GC 1156 k
                  </h4>
                  <p className="m-0 text-sm">
                    This is a cool planet named 'GC 1156 k' with an age of 7.94 billion earth years. 'GC 1156 k' presents a gaseous composition and has no satellites or rings. The duration of its day is 707 earth hours and the duration of its year is 795 earth days. There is no life in this planet.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header" style={{ marginBottom: '10px' }}>
                  <Image
                    src={require('./../../assets/images/funky_planet_16.png')}
                    alt="NJ 15794 h"
                    width={300}
                    height={300} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    NJ 15794 h
                  </h4>
                  <p className="m-0 text-sm">
                    This is a terrific planet named 'NJ 15794 h' with an age of 7.711 billion earth years. 'NJ 15794 h' has a big collision crater, presents a surface with small craters and has no satellites or rings. The duration of its day is 499 earth hours and the duration of its year is 682 earth days. There is no life in this planet.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header" style={{ marginBottom: '10px' }}>
                  <Image
                    src={require('./../../assets/images/funky_planet_65.png')}
                    alt="IH-VRF l"
                    width={300}
                    height={300} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    IH-VRF l
                  </h4>
                  <p className="m-0 text-sm">
                    This is a superb planet named 'IH-VRF l' with an age of 7.621 billion earth years. 'IH-VRF l' contains water and two continents and has no satellites or rings. The duration of its day is 596 earth hours and the duration of its year is 364 earth days. There is life in this planet and this life is intelligent. The population is 7,089,071,054 individuals and the the civilization's level of technological advancement is 'pre space exploration'.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header" style={{ marginBottom: '10px' }}>
                  <Image
                    src={require('./../../assets/images/funky_planet_101.png')}
                    alt="95 XHR j"
                    width={300}
                    height={300} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    95 XHR j
                  </h4>
                  <p className="m-0 text-sm">
                    This is a cool planet named '95 XHR j' with an age of 4.731 billion earth years. '95 XHR j' contains water and three continents, has rings and has no satellites. The duration of its day is 828 earth hours and the duration of its year is 164 earth days. There is life in this planet and this life is intelligent. The population is 4,515,383,902 individuals and the the civilization's level of technological advancement is 'industrial'.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FunkyUniverse.propTypes = propTypes;
FunkyUniverse.defaultProps = defaultProps;

export default FunkyUniverse;