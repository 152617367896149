import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Logo from '../layout/partials/Logo';
import { fetchData } from "../../redux/data/dataActions";
import web3 from "web3";
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Introduction = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const data = useSelector((state) => state.data);
  const blockchain = useSelector((state) => state.blockchain);
  const dispatch = useDispatch();
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const mintNFTs = () => {
    let cost = data.cost;
    let gasLimit = data.config.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${data.config.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: data.config.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${data.config.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > data.maxMintAmount) {
      newMintAmount = data.maxMintAmount;
    }
    setMintAmount(newMintAmount);
  };

  const getBlockchainContractData = () => {
    if (blockchain.account !== null && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses} style={{ paddingTop: '30px' }}>
          <div className="hero-content">
            {
              blockchain.errorMsg ?
                <div style={{ color: '#8c5ce6', textAlign: 'center', backgroundColor: '#25282c', borderRadius: '10px', marginBottom: '5px', padding: '10px' }}
                  dangerouslySetInnerHTML={{
                    __html: blockchain.errorMsg
                  }}></div>
                :
                ''
            }
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Funky Planets
            </h1>
            <div style={{ marginBottom: '12px', display: "inline-flex" }}>
              <a href={data.config.TWITTER_LINK} target={"_blank"} rel={"noopener noreferrer"} style={{ margin: '6px', verticalAlign: 'middle' }}>
                <img src={require('./../../assets/logos/twitter-logo.svg')} alt={'Twitter'} title={'Twitter'} width={30} style={{ marginTop: '2px'}} />
              </a>
              <a href={data.config.INSTAGRAM_LINK} target={"_blank"} rel={"noopener noreferrer"} style={{ margin: '6px', verticalAlign: 'middle' }}>
                <img src={require('./../../assets/logos/instagram-logo.svg')} alt={'Instagram'} title={'Instagram'} width={30} />
              </a>
              <a href={data.config.MARKETPLACE_OPENSEA_LINK} target={"_blank"} rel={"noopener noreferrer"} style={{ margin: '6px', verticalAlign: 'middle' }}>
                <img src={require('./../../assets/logos/opensea-logo.svg')} alt={'Opensea'} title={'Opensea'} width={30} />
              </a>
              {/* <a href={data.config.MARKETPLACE_RARIBLE_LINK} target={"_blank"} rel={"noopener noreferrer"} style={{ margin: '3px', verticalAlign: 'middle' }}>
                <img src={require('./../../assets/logos/rarible-logo.svg')} alt={'Rarible'} title={'Rarible'} width={30} />
              </a> */}
              <a href={data.config.SCAN_LINK + data.config.CONTRACT_ADDRESS} target={"_blank"} rel={"noopener noreferrer"} style={{ margin: '6px', verticalAlign: 'middle' }}>
                <img src={require('./../../assets/logos/etherscan-logo.svg')} alt={'contract'} title={'Contract'} width={30} />
              </a>
            </div>
            <div className="container-sm">
              <p className="m-0 mb-24 reveal-from-bottom" data-reveal-delay="400">
                At the time this NFT collection was created, NASA had 4525 exoplanets registered. This collection is the funky version of them. All planets were randomly and algorithmically generated with different traits like background, shape, rings, life, level of technological advancement, etc. Some planets will have satellites, water and even cities.
              </p>
              <p className="m-0 mb-24 reveal-from-bottom" data-reveal-delay="400">
                You now can be the owner of your own planet with its information. Each planet also counts with the QR code to read its information.
              </p>
              <div style={{ marginBottom: "24px" }}>
                <Logo />
              </div>
              <div style={{ fontSize: '15px' }}>
                <p style={{ marginBottom: '-10px' }}>Planets 1 to 1000 0.05 ether</p>
                <p style={{ marginBottom: '-10px' }}>Planets 1001 to 2000 0.055 ether</p>
                <p style={{ marginBottom: '-10px' }}>Planets 2001 to 3000 0.0605 ether</p>
                <p style={{ marginBottom: '-10px' }}>Planets 3001 to 4000 0.0668 ether</p>
                <p style={{ marginBottom: '-10px' }}>Planets 4000 to 4525 0.0732 ether</p>
              </div>
              <div>
                <div style={{ textAlign: 'center', backgroundColor: '#25282c', borderRadius: '10px', marginTop: '25px', paddingBlock: '30px', width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                  <p style={{ fontSize: '50px', color: 'white', fontWeight: 'bold' }}>{data.totalSupply} / {data.config.MAX_SUPPLY}</p>
                  {Number(data.totalSupply) >= data.config.MAX_SUPPLY ? (
                    <>
                      <p>The initial sale has sold out.</p>
                      <p>You can still find {data.config.NFT_NAME} on <a target={"_blank"} rel={"noopener noreferrer"} href={data.config.MARKETPLACE_OPENSEA_LINK}>Opensea</a> and <a target={"_blank"} rel={"noopener noreferrer"} href={data.config.MARKETPLACE_RARIBLE_LINK}>Rarible</a></p>
                    </>
                  ) : (
                    <>
                      {
                        blockchain.account !== null && blockchain.smartContract !== null ?
                          <>
                            <p>1 {data.config.SYMBOL} costs {web3.utils.fromWei(data.cost.toString(), 'ether')}{" "}{data.config.NETWORK.SYMBOL}.</p>
                            <p>Excluding gas fees.</p>
                            <p>{feedback}</p>
                            <ButtonGroup>
                              <Link to="" className="button button-primary button-wide-mobile button-sm"
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}>-</Link>
                              <span>{mintAmount}</span>
                              <Link to="" className="button button-primary button-wide-mobile button-sm"
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}>+</Link>
                            </ButtonGroup>
                            <ButtonGroup>
                              <Link to="" className="button button-primary button-wide-mobile button-sm"
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  mintNFTs();
                                  getBlockchainContractData();
                                }}>{claimingNft ? "The system is claimning your NFT" : "Buy"}</Link>
                            </ButtonGroup>
                          </>
                          :
                          <p>Connect your wallet</p>
                      }
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
}

Introduction.propTypes = propTypes;
Introduction.defaultProps = defaultProps;

export default Introduction;